<template>
  <div id="app">
    <Index />
  </div>
</template>

<script>
import Index from "@/components/Index";

export default {
  name: "App",
  components: {
    Index,
  },
};
</script>

<style>
</style>
