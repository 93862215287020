import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n';
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './css/style.css'
import './css/utilities.css'






new Vue({
    router,
    i18n,

    render: h => h(App),
}).$mount('#app')