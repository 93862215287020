import Vue from 'vue'
import Router from 'vue-router'
import i18n from './i18n'
Vue.config.productionTip = false;

Vue.use(Router)

function load(component) {
    // '@' is aliased to src/components
    return () =>
        import (`@/components/${component}.vue`)
}
export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
            path: '/',
            redirect: `/${i18n.locale}`
        },
        {
            path: '/:lang',
            name: 'Home',
            component: load('Home')
        },
        { path: '/:lang/recommend', name: 'Recommend', component: load('Recommend') },
        { path: '/:lang/match-result', name: 'MatchResult', component: load('MatchResult') },
        { path: '/:lang/statistics', name: 'Statistics', component: load('Statistics') },
        { path: '/:lang/experience', name: 'Experience', component: load('Experience') },
        { path: '/:lang/download-app', name: 'Download', component: load('Download') },



    ]
});