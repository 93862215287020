<template>
  <div style="min-width: 1480px">
    <!-- <Header /> -->
    <router-view></router-view>
    <!-- <Footer /> -->
  </div>
</template>

<script>
// import Header from "@/components/Header.vue";
// import Footer from "@/components/Footer.vue";

export default {
  name: "Index",
  components: {
    // Header,
    // Footer,
  },
  props: {
    msg: String,
  },
};
</script>

<style scoped>
</style>
